const sendMailData = {
    en: {
        title: "Successfully sent!",
        subTitle: "A bottle with your message in it is already on its way to us.",
        desc: "Fear not, no torture awaits, but unless you confirm your registration in the e-mail we just pinged your way, we’re under strict orders from the royal fleet to steer clear…",
        button: "Send another",
    },
    nl: {
        title: "Succesvol verzonden!",
        subTitle: "Een fles met uw boodschap erin is al onderweg naar ons.",
        desc: "Vrees niet, er wacht geen marteling, maar tenzij u uw registratie bevestigt in de e-mail die we zojuist uw kant op hebben gestuurd, staan we onder strikte orders van de koninklijke vloot om uit de buurt te blijven...",
        button: "Stuur een andere",
    },
};

const frabchisingSentData = {
    en: {
        title: "Successfully sent!",
        subTitle: "A bottle with your message in it is already on its way to us. As soon as we get your parchment, we will hurry to answer.",
        button: "Send another",
    },
    nl: {
        title: "Succesvol verzonden!",
        subTitle: "Een fles met uw boodschap erin is al onderweg naar ons. Zodra we uw perkament ontvangen, zullen we ons haasten om te antwoorden.",
        button: "Stuur een andere",
    },
};

export {
    sendMailData,
    frabchisingSentData
};
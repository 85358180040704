import { Fragment } from "react";
const newsletter = {
    en: {
        title: "WANT TO BE THE FIRST?",
        subttl: "Back in the olden days, you couldn’t get hold of exclusive information without having to undergo torture, walk the plank or be subjected to any keelhauling. These days you just need to send us an e-mail and hey presto!",
        placeholder: "Enter your e-mail address",
        button: "Sing up",
        error: "Body of a thousand whales! This email address has already been entered by someone else! Enter another email address.",
        emailExist: <Fragment>Confound it, this e-mail was entered by somebody before you! <br />Enter another e-mail, please.</Fragment>
    },
    nl: {
        title: "Als eerste de buit veroveren?",
        subttl: "Luister aandachtig maten! Vergeet de dagen van marteling en kielhalen. Het was vroeger onmogelijk om een schatkaart te bemachtigen zonder bloedvergieten. Tegenwoordig hoef je alleen je e-mail adres achter te laten voor aanwijzingen",
        placeholder: "Voer je e-mailadres in",
        button: "Aanmelden",
        error: "Lichaam van duizend walvissen! Dit e-mailadres is al door iemand anders ingevoerd! Voer een ander e-mailadres in.",
        emailExist: <Fragment>Verwar het maar, deze e-mail is ingevoerd door iemand voor jou! <br />Voer een ander e-mailadres in, alstublieft.</Fragment>
    },
};

export {
    newsletter
};
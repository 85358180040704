import logoEn from "../img/icons/en.jpg";
import logoNl from "../imgnl/nl.png";

const firstMenu = {
    en: [
        {
            id: "products",
            path: "/products",
            name: "Products",
        },
        {
            id: "captain",
            path: "/captain-story",
            name: "The Captain’s story",
        },
    ],
    nl: [
        {
            id: "products",
            path: "/products",
            name: "Producten",
        },
        {
            id: "captain",
            path: "/captain-story",
            name: "Kapitein verhaal",
        },
    ],
    de: [
        {
            id: "products",
            path: "/products",
            name: "Produkte",
        },
        {
            id: "captain",
            path: "/captain-story",
            name: "Die Geschichte des Kapitäns",
        },
    ],
};

const secondMenu = {
    en: [
        {
            id: "about",
            path: "/about",
            name: "About us",
        },
        {
            id: "stores",
            path: "/stores",
            name: "Stores",
        },
    ],
    nl: [
        {
            id: "about",
            path: "/about",
            name: "Over ons",
        },
        {
            id: "stores",
            path: "/stores",
            name: "Onze winkels",
        },
    ],
    de: [
        {
            id: "about",
            path: "/about",
            name: "Über uns",
        },
        {
            id: "stores",
            path: "/stores",
            name: "Shops",
        },
    ],
};

const footerMenu = {
    en: [
        {
            id: "career",
            path: "/career",
            name: "Career",
        },
        {
            id: "faq",
            path: "/faq",
            name: "FAQ",
        },
        {
            id: "franchising",
            path: "/franchising",
            name: "Franchising",
        },
        {
            id: "contact",
            path: "/contact",
            name: "Contact",
        },
    ],
    nl: [
        {
            id: "career",
            path: "/career",
            name: "Carrière",
        },
        {
            id: "faq",
            path: "/faq",
            name: "FAQ",
        },
        {
            id: "franchising",
            path: "/franchising",
            name: "Franchising",
        },
        {
            id: "contact",
            path: "/contact",
            name: "Contact",
        },
    ],
    de: [
        {
            id: "career",
            path: "/career",
            name: "Karriere",
        },
        {
            id: "faq",
            path: "/faq",
            name: "FAQ",
        },
        {
            id: "franchising",
            path: "/franchising",
            name: "Franchise",
        },
        {
            id: "contact",
            path: "/contact",
            name: "Kontakt",
        },
    ],
};

const language = {
    nl: {
        code: "en",
        name: "English",
        img: logoEn,
    },
    en: {
        code: "nl",
        name: "Dutch",
        img: logoNl,
    },
    // en: {
    //     code: "nl",
    //     name: "German",
    //     img: logoDe,
    // },
    de: {
        code: "nl",
        name: "English",
        img: logoEn,
    },
};

const footerMenuSocial = [
    {
        path: "https://www.facebook.com/captaincandynetherlands",
        name: "/captaincandynetherlands",
        icon: "fb",
        iconName: "facebook"
    },
    {
        path: "https://www.instagram.com/captaincandy.nl",
        name: "/captaincandy.nl",
        icon: "ig",
        iconName: "instagram"
    },
    {
        path : "https://www.tiktok.com/@captaincandy.nl",
        name: "@captaincandy.nl",
        icon: "tik",
        iconName: "tiktok"
    },
    {
        path : "https://www.linkedin.com/company/captain-candy-candy-shop",
        name: "/linkedin",
        icon: "in",
        iconName: "linkedin"
    }
];

export {
    firstMenu,
    secondMenu,
    language,
    footerMenu,
    footerMenuSocial,
};
import React from 'react';
import { Link } from 'react-router-dom';
import AnimationEffects from '../../assets/Animations/hooks/AnimationEffects';

const HpBoxes = ({ boxes, title }) => {
    const elementsToAnimate = ['title',];
    const { singleRefs } = AnimationEffects(elementsToAnimate, boxes ,'fadeInY', 0.4, 0.12);
    const toTop = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    };
    return (
        <section className="boxes fade-onload">
            <div className="container-custom">
                <div className="row-custom">
                    <div className="spacer60 hidden-xs"></div>
                    <h2 className="section-heading opacity-0" ref={singleRefs.elements.title.ref}>
                        {title}
                    </h2>
                    <div className="boxes-wrap">
                        {boxes?.map((el, index) => (
                            <div key={el.img} className={`box opacity-0 ${el?.longer && "-longer"}`} ref={singleRefs.items[index].ref}>
                                <Link onClick={() => toTop()} to={el.href} className="inner">
                                    <div className="img-wrap">
                                        <img src={el.img} alt="LIQUORICE" />
                                    </div>
                                    <div className="content text-center">
                                        <h3 className="ttl">
                                            {el.title}
                                        </h3>
                                        <span className="text">{el.description}</span>
                                        <div className="link-wrap"><span className="link">{el.link}</span></div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HpBoxes;

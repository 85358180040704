const emailSuccessData = {
    en: {
        title: "Confirm your e-mail – it’s torture-free we promise.",
        subttl: "We will not torture you, but if you don't confirm your login in the e-mail we just sent to you, you are forbidden to join the royal fleet ...",
        subttl1: "You’re just one step away – so get over to that inbox and confirm the message.",
        subttl2: "Thanks and here’s to a plain sailing voyage together."
    },
    nl: {
        title: "Ahoy, bevestiging nodig voor flessenpost",
        subttl: "Wij beloven op onze eer als zeerovers, je niet te martelen met vervelende post. Bevestig je aanmelding, in de e-mail die we zojuist hebben gestuurd. Zo niet? Dan zal het onmogelijk zijn, om bij ons aan boord te gaan.",
        subttl1: "Je bent nog maar één stap verwijderd, dus zet koers naar je inbox en bevestig je aanmelding!",
        subttl2: "Bedankt en op een vlotte zeilreis samen."
    },
};

const emailConfirmData = {
    en: {
        title: "We have it!",
        subttl: "Your email has been successfully stored in our hold and is coming with us on our expeditions.",
        subttl1: "Just so you know - we've got a shipwrecked scribe on board, so he'll write you a scroll as soon as something new happens.",
        subttl2: "Look forward!",
        buttonName: "Continue",
    },
    nl: {
        title: "We hebben het!",
        subttl: "Uw e-mailadres is met succes in ons bezit opgeslagen en gaat met ons mee op onze expedities.",
        subttl1: "Zodat je het weet - we hebben een schipbreukeling aan boord, dus hij zal je een rol schrijven zodra er iets nieuws gebeurt.",
        subttl2: "Kijk uit naar!",
        buttonName: "Doorgaan",
    },
};

export {
    emailSuccessData, emailConfirmData,
};
import React, { useContext, useState } from 'react';
import About from '../components/About/About';
import AnimationEffects from '../assets/Animations/hooks/AnimationEffects';
import NewLetter from '../components/NewLetter/NewLetter';
import { AboutData } from '../assets/dummyData/About';
import { Context } from '../context/Products';
import { NewsLetterContent } from '../assets/dummyData/NewsLetterData';
import Helmet from '../components/Helmet';
import logo from "../assets/img/prodejny_map.png";
import ModalEmailSuccess from '../components/ModalEmailSuccess/ModalEmailSuccess';

const AboutPage = () => {
    const [{ lang }] = useContext(Context);
    const { singleRefs } = AnimationEffects(['NewLetter'], [] ,'opacity', 0.2 , 0.12);
    const [shoeModalEmail, setShoeModalEmail] = useState(false);

    const modalEmail = () => {
        setShoeModalEmail(true);
        const body = document.body;
        body.classList.add("modal-open");
        setTimeout(() => {
            setShoeModalEmail(false);
        }, 500);
    }

    return (
        <>
            <div id="page-about">
                <Helmet
                    title="Over | Captain Candy"
                    metaDesc="Bij Captain Candy bieden we zoetwaren van de beste kwaliteit. U vindt er heerlijke geleien, diverse chocolaatjes, heerlijke dropsnoepjes, marshmallows, maar ook kauwgom en lolly's. Er is iets voor iedereen."
                    opengraphImage={logo}
                />
                <About aboutData={AboutData[lang]} footerText={AboutData.footerText[lang]} />
            </div>
            <div className="opacity-0" ref={singleRefs.elements.NewLetter.ref}>
                <NewLetter data={NewsLetterContent[lang]} lang={lang} openModal={modalEmail} />
            </div>
            <ModalEmailSuccess open={shoeModalEmail} />
        </>
    )
}

export default AboutPage;
import mapMarker from "../img/icons/map_marker.svg";

const locations = {
    en: [
            {
                id: "1",
                position: { lat:  52.37069480816792, lng: 4.891576315967946 },
                title: "Kalverstraat 80-82",
                link: "https://www.google.com/maps/place/Kalverstraat+80,+1012+PH+Amsterdam,+Nizozemsko/@52.3706901,4.8914355,21z/data=!4m5!3m4!1s0x47c609c11f22fbf3:0x99f1192a9efc529b!8m2!3d52.3706901!4d4.8915723",
                icon: mapMarker,
                table: [
                    {
                        open: "Monday",
                        time: "11:00—20:00",
                    },
                    {
                        open: "Tuesday",
                        time: "10:00—20:00",
                    },
                    {
                        open: "Wednesday",
                        time: "10:00—20:00",
                    },
                    {
                        open: "Thursday",
                        time: "10:00—21:00",
                    },
                    {
                        open: "Friday",
                        time: "10:00—20:00",
                    },
                    {
                        open: "Saturday",
                        time: "10:00—20:00",
                    },
                    {
                        open: "Sunday",
                        time: "11:00—20:00",
                    },
                ]
            },
            {
                id: "2",
                position: { lat: 52.37531920523375, lng: 4.894792735582172 },
                title: "Nieuwendijk 169",
                link: "https://goo.gl/maps/BG12c4EqxvjW9SGx8",
                icon: mapMarker,
                table: [
                    {
                        open: "Monday",
                        time: "11:00—20:00",
                    },
                    {
                        open: "Tuesday",
                        time: "10:00—20:00",
                    },
                    {
                        open: "Wednesday",
                        time: "10:00—20:00",
                    },
                    {
                        open: "Thursday",
                        time: "10:00—21:00",
                    },
                    {
                        open: "Friday",
                        time: "10:00—20:00",
                    },
                    {
                        open: "Saturday",
                        time: "10:00—20:00",
                    },
                    {
                        open: "Sunday",
                        time: "11:00—20:00",
                    },
                ]
            }
        ],
    nl:  [
        {
            id: "1",
            position: { lat:  52.37069480816792, lng: 4.891576315967946 },
            title: "Kalverstraat 80-82",
            link: "https://www.google.com/maps/place/Kalverstraat+80,+1012+PH+Amsterdam,+Nizozemsko/@52.3706901,4.8914355,21z/data=!4m5!3m4!1s0x47c609c11f22fbf3:0x99f1192a9efc529b!8m2!3d52.3706901!4d4.8915723",
            icon: mapMarker,
            table: [
                {
                    open: "Maandag",
                    time: "11:00—20:00",
                },
                {
                    open: "Dinsdag",
                    time: "10:00—20:00",
                },
                {
                    open: "Woensdag",
                    time: "10:00—20:00",
                },
                {
                    open: "Donderdag",
                    time: "10:00—21:00",
                },
                {
                    open: "Vrijdag",
                    time: "10:00—20:00",
                },
                {
                    open: "Zaterdag",
                    time: "10:00—20:00",
                },
                {
                    open: "Zondag",
                    time: "11:00—20:00",
                },
            ]
        },
        {
            id: "2",
            position: { lat: 52.37531920523375, lng: 4.894792735582172 },
            title: "Nieuwendijk 169",
            link: "https://goo.gl/maps/BG12c4EqxvjW9SGx8",
            icon: mapMarker,
            table: [
                {
                    open: "Maandag",
                    time: "11:00—20:00",
                },
                {
                    open: "Dinsdag",
                    time: "10:00—20:00",
                },
                {
                    open: "Woensdag",
                    time: "10:00—20:00",
                },
                {
                    open: "Donderdag",
                    time: "10:00—21:00",
                },
                {
                    open: "Vrijdag",
                    time: "10:00—20:00",
                },
                {
                    open: "Zaterdag",
                    time: "10:00—20:00",
                },
                {
                    open: "Zondag",
                    time: "11:00—20:00",
                },
            ]
        }
    ]
}

export {
    locations
};

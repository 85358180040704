import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";

const toTop = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
};

const handleScroll = () => {
    const section = document.getElementById( 'kontakt' );
    section.scrollIntoView( { behavior: 'smooth'} );
};

const contactData = {
    en: {
        title: "Contact us",
        history: <Fragment>
            Do you have something on your mind? Would you like to find something out from us? Don’t be afraid to write to us! <br />You can write us a scroll and send it to  <a href="mailto:info.nl@captaincandy.eu">info.nl@captaincandy.eu</a>, or fill in an entry in  <button className="button-underline" onClick={() => handleScroll()}>our logbook bellow</button>.
            </Fragment>,
        historyLink: <Fragment>If you’re interested in getting a franchise, <Link to="/franchising">go here</Link> to find out more.
        </Fragment>,
        questionTitle: "Send a question or request",
        inputs: [
            {
                id: "contactform_fullName",
                name: "contactform_fullName",
                label: "Name and surname",
            },
            {
                id: "contactform_email",
                name: "contactform_email",
                label: "E-mail",
            },
            {
                id: "contactform_phone",
                name: "contactform_phone",
                label: "Telephone number",
                class: "inputphone",
                style: { display: "inline-block" },
            },
            {
                id: "contactform_text",
                name: "contactform_text",
                label: "What is it?",
                textarea: true,
            },
        ],
        link: <Fragment>“I consent to the <Link  onClick={() => toTop()} to="/personal-data-processing" className="link">processing of my personal data</Link> and the responses given in the questionnaire.</Fragment>,
        button: "Send message"
    },
    nl: {
        title: "Neem contact met ons op",
        history: <Fragment>
            Heb je iets op je lever? Wil je iets van ons weten? Wees niet bang om ons te schrijven!
            <br />
            Stuur je elektronische post naar <a href="mailto:info.nl@captaincandy.eu">info.nl@captaincandy.eu</a>, of stuur je vraag/verzoek via het formulier <button className="button-underline" onClick={() => handleScroll('kontakt')}>hieronder</button>.
            </Fragment>,
        historyLink: <Fragment>Meer weten over het opzetten van een franchise bij Captain Candy, <Link onClick={() => toTop()} to="/franchising">klik hier</Link> om meer erover te weten te komen.</Fragment>,
        questionTitle: "Stuur een vraag of verzoek",
        inputs: [
            {
                id: "contactform_fullName",
                name: "contactform_fullName",
                label: "Voor- en achternaam",
            },
            {
                id: "contactform_email",
                name: "contactform_email",
                label: "E-mail",
            },
            {
                id: "contactform_phone",
                name: "contactform_phone",
                label: "Telefoonnummer",
                class: "inputphone",
                style: { display: "inline-block" },
            },
            {
                id: "contactform_text",
                name: "contactform_text",
                label: "Je vraag/verzoek",
                textarea: true,
            },
        ],
        link: <Fragment>“Ik stem in met de verwerking van mijn  <Link  onClick={() => toTop()} to="/personal-data-processing" className="link">persoonsgegevens en mijn vraag/verzoek</Link>”</Fragment>,
        button: "Bericht versturen"
    },
};

export {
    contactData,
}
import React, { useContext, useState } from 'react';
import { faqData } from '../assets/dummyData/faq';
import { NewsLetterContentFaq } from '../assets/dummyData/NewsLetterData';
import Faq from '../components/Faq/Faq';
import Helmet from '../components/Helmet';
import ModalEmailSuccess from '../components/ModalEmailSuccess/ModalEmailSuccess';
import NewLetter from '../components/NewLetter/NewLetter';
import { Context } from '../context/Products';


const FaqPage = () => {
  const [{ lang }] = useContext(Context);
  const [shoeModalEmail, setShoeModalEmail] = useState(false);

  const modalEmail = () => {
      setShoeModalEmail(true);
      const body = document.body;
      body.classList.add("modal-open");
      setTimeout(() => {
          setShoeModalEmail(false);
      }, 500);
  }
  return (
    <div id="page-faq" className="page">
      <Helmet
        title="Faq | Captain Candy"
        metaDesc="Bij Captain Candy bieden we zoetwaren van de beste kwaliteit. U vindt er heerlijke geleien, diverse chocolaatjes, heerlijke dropsnoepjes, marshmallows, maar ook kauwgom en lolly's. Er is iets voor iedereen."
      />
      <Faq data={faqData[lang]} title={faqData?.title[lang]} />
      <NewLetter hiddeImg data={NewsLetterContentFaq[lang]} openModal={modalEmail} lang={lang} />
      <ModalEmailSuccess open={shoeModalEmail} />
    </div>
  )
}

export default FaqPage;
import { Fragment } from "react";
import hpAboutImg from "../img/hp_about_1_inner-min.jpg";
import hpAboutInner from "../img/hp_about_2_inner-min.jpg";
import promoText from "../img/promo_text-sm_en.svg";
import promoTextXs from "../img/promo_text-xs_en.svg";
import promoTextXsNl from "../img/web/welcome-nl-xs.svg";
import promoTextNl from "../img/web/welcome-nl.svg";

const homePageData = {
    en: [
        {
            id: "123",
            img: hpAboutImg,
            title: "Find your treasure",
            description: "Raise the anchor of your imagination and set sail with us to discover the taste of eternal youth. Each of our candies conceals a different story!",
            link: "/products#tangy-jelly",
            buttonName: <Fragment>Explore candy <span>islands</span></Fragment>,
        },
        {
            id: "124",
            img: hpAboutInner,
            title: "I, Captain Candy",
            description: <Fragment>Discover a story full of adventure,<br />suspense and knowledge</Fragment>,
            link: "/captain-story",
            buttonName: <Fragment>My story</Fragment>,
        },
    ],
    nl: [
        {
            id: "123",
            img: hpAboutImg,
            title: "Zoek je schat",
            description: "Op zoek naar de schat matroos? Geef je verbeelding vrij spel en zet koers met ons om de eeuwige jeugd te veroveren. Elk van onze zoete schatten heeft een uniek verhaal, dus kom aan boord en ontdek het zelf!",
            link: "/products",
            buttonName: <Fragment>Verken onze snoeptonnen</Fragment>,
        },
        {
            id: "124",
            img: hpAboutInner,
            title: "Het verhaal van de kapitaal",
            description: <Fragment>Luister aandachtig! Als kapitein neem ik jullie mee op mijn zoektocht voor avontuur</Fragment>,
            link: "/captain-story",
            buttonName: <Fragment>Mijn verhaal</Fragment>,
        },
    ],
};

const promoTextHeader = {
    en: {
        text: "Welcome on varis board",
        promoText: promoText,
        promoTextXs: promoTextXs,
    },
    nl: {
        text: "Welkom op varisbord",
        promoText: promoTextNl,
        promoTextXs: promoTextXsNl,
    },
};

export {
    homePageData,
    promoTextHeader,
};
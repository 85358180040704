import { Fragment } from "react";
import { Link } from "react-router-dom";
const toTop = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
};

const franchisingData = {
    en: {
        title: "Captain Candy Franchising",
        history: [
            {
                question: "Captain Candy concept caught your eye?",
                answer: <Fragment>Captain Candy is a successful and proven concept operating a network of candy shops. Our shops are so unique that in many cases they have been the subject of illegal copying. However, there is only one original Captain Candy. In our stores, we do not only focus on a wide selection of the most delicious candies, but the overall customer experience is just as important to us.<br />          With a Captain Candy franchise, you can offer your customers the joy of discovery and the excitement of returning to their childhood. <br />For more information, please fill out the questionnaire below.</Fragment>,
            },
            {
                question: "Keen to spread the joy of returning to being a kid to others around you?",
                answer: "Check out what you can gain from a Captain Candy franchise and get in touch. We can then talk through the rest in person.",
            },
        ],
        list: [
            {
                headingtitle: "Why work with us as a franchising partner?",
                headingLi: [
                    'Our concept boasts its own unique style',
                    "We offer quality of an international calibre",
                    "We’re constantly developing and improving",
                    "We’re a tried and tested European brand",
                ],
            },
            {
                headingtitle: "What we offer franchising partners?",
                headingLi: [
                    "The security of working for an international brand",
                    "Tried and tested sales know-how, which is constantly being innovated",
                    "Well-thought out, tried and tested and ready-made solutions",
                    "Help not only before opening your store and in the early stages, but also any time further down the line",
                    "The opportunity for personal development and self-realisation in getting your business off the ground",
                    "The chance to contribute to the development and growth of the brand",
                    "Stocking up with products and the necessary equipment to operate a franchise",
                    "System of regular checks and consultations",
                    "Long-term income and appreciation of your investment",
                    "Mutual agreement on a specific form of cooperation",
                ]
            }
        ],
        listParagraphsTitle: "How to get a Captain Candy franchise",
        listParagraphs:  [
            "We see franchising as a longstanding and mutually beneficial relationship, a bit like accepting another person into our business family. We therefore take great care to ensure our selection process is fair, thorough and prudent.",
            " For this reason anyone interested in applying is asked to complete a QUESTIONNAIRE to share preliminary (and non-binding) information with us, which allows us to ascertain whether our future collaboration is built on sufficiently solid foundations and has real prospects for success. We’ll get in touch after reading through your responses.",
            <Fragment><i> By sending your responses, you are automatically entered onto our database of people interested in working with us. We treat any information contained in the questionnaire in accordance with the General Data Protection Regulation (GDPR) and use it exclusively for the purpose of potential collaboration with you.</i></Fragment>,
        ],
        questionTitle: "QUESTIONNAIRE",
        inputs: [
            {
                id: "contactform_fullName",
                name: "contactform_fullName",
                label: "Name and surname*",
            },
            {
                id: "contactform_email",
                name: "contactform_email",
                label: "E-mail*",
            },
            {
                id: "contactform_phone",
                name: "contactform_phone",
                label: "Telephone number*",
                class: "inputphone",
                style: { display: "inline-block" },
            },
            {
                id: "contactform_text_1",
                name: "contactform_text_1",
                label: "Why do you want to work with Captain Candy?",
                textarea: true,
            },
            {
                id: "contactform_text_2",
                name: "contactform_text_2",
                label: "How did you find out about Captain Candy?",
                textarea: true,
            },
            {
                id: "contactform_text_3",
                name: "contactform_text_3",
                label: "What is your current professional experience?*",
                textarea: true,
            },
            {
                id: "file_upload",
                name: "file_upload",
                label: "Please attach a detailed CV*",
                button: "Browse"
            },
            {
                id: "contactform_text_4",
                name: "contactform_text_4",
                label: "Do you have any experience running a small business? If o what?*",
                textarea: true,
            },
            {
                id: "contactform_text_5",
                name: "contactform_text_5",
                label: "Do you work for any other brands?*",
                textarea: true,
            },
            {
                id: "contactform_text_6",
                name: "contactform_text_6",
                label: "Do you have any other commitments or things that would restrict you from this work? Have you signed a competition clause preventing you from working with a particular brand or concept?*",
                textarea: true,
            },
            {
                id: "contactform_text_7",
                name: "contactform_text_7",
                label: "Do you or have you (or anyone close to you) previously run a franchise?",
                textarea: true,
            },
            {
                id: "contactform_text_8",
                name: "contactform_text_8",
                label: "If you’ve never run your own business before, what is your motivation for starting one now?",
                textarea: true,
            },
            {
                id: "contactform_text_9",
                name: "contactform_text_9",
                label: "Do you have a clean criminal record and are you prepared to supply proof of this?*",
                textarea: true,
            },
            {
                id: "contactform_text_10",
                name: "contactform_text_10",
                label: "To what degree can you take part in the day to day running of the store?*",
                textarea: true,
            },
            {
                id: "contactform_text_11",
                name: "contactform_text_11",
                label: "What makes you think that Captain Candy will be a success in your city, country or region?",
                textarea: true,
            },
            {
                id: "contactform_text_12",
                name: "contactform_text_12",
                label: "Where do you want to open your premises? What town, big city, region or country interest you in terms of running Captain Candy franchising stores?*",
                textarea: true,
            },
            {
                id: "contactform_text_13",
                name: "contactform_text_13",
                label: "Do you have your own/rented sales spaces to run your Captain Candy store? How big is it, what are the floor plans and where is it? How long will you have it for?",
                textarea: true,
            },
            {
                id: "contactform_text_14",
                name: "contactform_text_14",
                label: "What level of funds are you prepared to invest in the Captain Candy project? How do you plan to finance construction, furnishings and running costs during the start-up phase? How much can you source from your own funds?*",
                textarea: true,
            },
        ],
        link: <Fragment>“I consent to the  <Link  onClick={() => toTop()} to="/personal-data-processing" className="link">processing of my personal data</Link> and the responses given in the questionnaire.</Fragment>,
        button: "Send"
    },
    nl: {
        title: "Captain Candy-franchising",
        history: [
            {
                question: "Vind je het Captain Candy concept interessant?",
                answer: <Fragment>Captain Candy is een snoepwinkel uit Tsjechië met een groot assortiment aan kant-en-klare snoepjes. De eerste winkel opende in 2012 in Praag, waarna uitbreiding volgde naar Italië en Nederland. Momenteel hebben we al meerdere winkels en zijn we van plan om er nog meer te openen.</Fragment>,
            },
            {
                question: "Wil je jouw ontdekkingstocht en enthousiasme voor het herbeleven van jouw jeugd delen met anderen?",
                answer: "Dan is een Captain Candy-franchise wellicht iets voor jou! Neem gerust contact met ons op en we bespreken graag samen de volgende stappen.",
            },
        ],
        list: [
            {
                headingtitle: "Waarom kiezen voor ons als franchise-partner?",
                headingLi: [
                    "Ons unieke concept heeft een eigen stijl",
                    "Wij bieden kwaliteit op internationaal niveau",
                    "Blijven continu ontwikkelen en verbeteren ",
                    "Een bewezen Europees merk",
                ],
            },
            {
                headingtitle: "Wat bieden wij onze franchisepartners?",
                headingLi: [
                    "We geven je de zekerheid van een succesvolle onderneming onder een internationaal merk",
                    "Met bewezen kennis die voortdurend wordt verbeterd.",
                    "Op maat gemaakte en bewezen oplossingen, die goed doordacht en getest zijn ",
                    "We bieden niet alleen hulp vóór en tijdens de opening, maar ook op elk moment daarna",
                    "Kans voor persoonlijke ontwikkeling en zelfontplooiing bij de lancering van je eigen winkel",
                    "Kans om deel te nemen aan ontwikkeling en groei van het merk",
                    "We leveren de benodigde producten en inrichting voor het exploiteren van de franchise",
                    "Periodiek overleg en afstemming met elkaar",
                    "Bij ons ben je verzekerd van een lange termijn inkomen en rendement op je investering",
                    "We stemmen met elkaar de vorm van samenwerking af Kortom, bij ons vind je alles wat je nodig hebt om een succesvolle franchisenemer te worden.",
                ]
            }
        ],
        listParagraphsTitle: "Hoe start je een Captain Candy franchise?",
        listParagraphs:  [
            "Bij Captain Candy beschouwen we franchising als een langdurige en wederzijds productieve relatie, waarbij we de nieuwe leden opnemen in onze zakenfamilie. Daarom beoordelen we de selectie zorgvuldig en weloverwogen.",
            "Als je geïnteresseerd bent in een franchise, beginnen we met een voorlopige en vrijblijvende informatie uitwisseling met behulp van een vragenlijst. Hiermee willen we ontdekken of onze eventuele samenwerking voldoende draagvlak heeft en realistische is. Nadat we je antwoorden hebben ontvangen, nemen we contact met je op.",
            <Fragment><i>Door de vragenlijst in te vullen en te versturen, word je automatisch opgenomen in onze database van geïnteresseerden voor samenwerking. We behandelen deze informatie in overeenstemming met de Algemene Verordening Gegevensbescherming (AVG) en gebruiken deze alleen om samenwerking te bewerkstelligen.</i></Fragment>,
        ],
        questionTitle: "VRAGENLIJST",
        inputs: [
            {
                id: "contactform_fullName",
                name: "contactform_fullName",
                label: "Voornaam en achternaam*",
            },
            {
                id: "contactform_email",
                name: "contactform_email",
                label: "E-mail*",
            },
            {
                id: "contactform_phone",
                name: "contactform_phone",
                label: "Telefoonnummer*",
                class: "inputphone",
                style: { display: "inline-block" },
            },
            {
                id: "contactform_text_1",
                name: "contactform_text_1",
                label: "Waarom wil je met Captain Candy samenwerken?",
                textarea: true,
            },
            {
                id: "contactform_text_2",
                name: "contactform_text_2",
                label: "Hoe heb je over Captain Candy gehoord?",
                textarea: true,
            },
            {
                id: "contactform_text_3",
                name: "contactform_text_3",
                label: "Wat is je vorige professionele ervaring?*",
                textarea: true,
            },
            {
                id: "file_upload",
                name: "file_upload",
                label: "Voeg een actueel CV toe*",
                button: "Toevoegen"
            },
            {
                id: "contactform_text_4",
                name: "contactform_text_4",
                label: "Heb je ervaring met het runnen van een retail-winkel? Zo ja, welke?*",
                textarea: true,
            },
            {
                id: "contactform_text_5",
                name: "contactform_text_5",
                label: "Werk je nog voor een ander merk?*",
                textarea: true,
            },
            {
                id: "contactform_text_6",
                name: "contactform_text_6",
                label: "Heb je verplichtingen, beperkingen of verbod op concurrentie opgelegd door een merk of concept?*",
                textarea: true,
            },
            {
                id: "contactform_text_7",
                name: "contactform_text_7",
                label: "Heb je of had je (of iemand in je omgeving) ooit een franchise?",
                textarea: true,
            },
            {
                id: "contactform_text_8",
                name: "contactform_text_8",
                label: "Als je nog nooit een onderneming had, was zijn nu je overwegingen om te beginnen?",
                textarea: true,
            },
            {
                id: "contactform_text_9",
                name: "contactform_text_9",
                label: "Heb je geen strafblad en ben je bereid om een Verklaring Omtrent het Gedrag (VOG) aan te leveren?*",
                textarea: true,
            },
            {
                id: "contactform_text_10",
                name: "contactform_text_10",
                label: "In hoeverre kun je je actief inzetten voor het dagelijkse beheer van de winkel?*",
                textarea: true,
            },
            {
                id: "contactform_text_11",
                name: "contactform_text_11",
                label: "Waarom denk je dat Captain Candy zou kunnen slagen in jouw stad, regio of land?",
                textarea: true,
            },
            {
                id: "contactform_text_12",
                name: "contactform_text_12",
                label: "Waar wil je een winkel openen? In welke stad (steden), hoofdstad (hoofdsteden), regio(-s) of land(en) ben je geïnteresseerd voor exploitatie van het Captain Candy-franchise?*",
                textarea: true,
            },
            {
                id: "contactform_text_13",
                name: "contactform_text_13",
                label: "Heb je je eigen/gehuurde verkoopruimte tot je beschikking voor de Captain Candy winkel? Hoe groot, wat is de plattegrond en wat is de locatie ervan? Hoe lang zal je de ruimte tot je beschikking hebben?",
                textarea: true,
            },
            {
                id: "contactform_text_14",
                name: "contactform_text_14",
                label: "Hoeveel middelen heb je beschikbaar voor het Captain Candy-project? Hoe ben je van plan de bouw, inrichting en exploitatie tijdens de opstart van de winkel te financieren? Wat voor eigen vermogen kan je in de onderneming investeren?*",
                textarea: true,
            },
        ],
        link: <Fragment>“Ik ga akkoord met de voorwaarden voor verwerken van<Link  onClick={() => toTop()} to="/personal-data-processing" className="link"> persoonsgegevens </Link>  en antwoorden in deze vragenlijst.“</Fragment>,
        button: "VERZENDEN"
    },
};

export {
    franchisingData,
}
import React, { useContext } from 'react';
import { useState } from 'react';
import AnimationEffects from '../assets/Animations/hooks/AnimationEffects';
import { NewsLetterContent } from '../assets/dummyData/NewsLetterData';
import { productsItems } from '../assets/dummyData/ProductsPageData';
import ModalEmailSuccess from '../components/ModalEmailSuccess/ModalEmailSuccess';
import NewLetter from '../components/NewLetter/NewLetter';
import Products from '../components/Products/Products';
import { Context } from '../context/Products';
import Helmet from "../components/Helmet";

const ProductsPage = () => {
    const [shoeModalEmail, setShoeModalEmail] = useState(false);
    const [{ lang }] = useContext(Context);
    const { singleRefs } = AnimationEffects(['NewLetter'], [] ,'fadeInOnce', 0.4, 0.12);

    const modalEmail = () => {
        setShoeModalEmail(true);
        const body = document.body;
        body.classList.add("modal-open");
        setTimeout(() => {
            setShoeModalEmail(false);
        }, 500);
    }
    return (
        <>
            <div id="page-products" className="page fade-onload">

                <Helmet
                    title="Producten | Kapitein Candy"
                    metaDesc="Er zijn duizenden smaken in de wereld. Kies bij ons jouw favoriet. We doen er alles aan om u snoep van de beste kwaliteit van de beste leveranciers aan te bieden om zelfs de meest veeleisende zoetekauw tevreden te stellen."
                />
                <Products
                    productsItems={productsItems[lang]}
                    title={productsItems.headTitle[lang]}
                    subTitle={productsItems.headSubTitle[lang]}
                    stores={productsItems.stores[lang]}
                />
                <div className="opacity-0" ref={singleRefs.elements.NewLetter.ref}>
                    <NewLetter data={NewsLetterContent[lang]} openModal={modalEmail} lang={lang} />
                </div>
            </div>
            <ModalEmailSuccess open={shoeModalEmail} />
        </>
    )
}

export default ProductsPage;

import React, { useContext } from 'react';
import { homePageData, promoTextHeader } from '../assets/dummyData/homePage';
import { boxes, HpMapData } from '../assets/dummyData/HpBoxesData';
import { locations } from '../assets/dummyData/map';
import baner1 from "../assets/img/slider/banner1.jpg";

import Header from '../components/Header/Header';
import HpAbout from '../components/HpAbout/HpAbout';
import HpBoxes from '../components/HpBoxes/HpBoxes';
import HpMap from '../components/Hpmap/HpMap';
import { Context } from '../context/Products';
import Helmet from "../components/Helmet";

const HomePage = () => {
  const [{ lang }] = useContext(Context);
  return (
    <div id="page-hp" className="page">
      <Helmet
        title="Snoepwinkel | Kapitein Candy"
        metaDesc="Bij Captain Candy bieden we zoetwaren van de beste kwaliteit. U vindt er heerlijke geleien, diverse chocolaatjes, heerlijke dropsnoepjes, marshmallows, maar ook kauwgom en lolly's. Er is iets voor iedereen."
        opengraphImage={baner1}
      />
      <Header promoText={promoTextHeader[lang]} />
        <HpAbout
          data={homePageData[lang]}
        />
        <HpBoxes
          boxes={boxes[lang]}
          title={boxes.title[lang]}
        />
        <div className="clearfix" />
        <HpMap data={HpMapData[lang]} locations={locations[lang]} />
    </div>
  )
}

export default HomePage;

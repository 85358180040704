import React, { useContext } from 'react';
import { dataProcessing } from '../assets/dummyData/faq';
import Faq from '../components/Faq/Faq';
import Helmet from '../components/Helmet';
import { Context } from '../context/Products';

const PersonalDataProcessingPage = () => {
  const [{ lang }] = useContext(Context);
  return (
    <div id="page-faq" className="page">
        <Helmet
          title="Personal Data Processing | Kapitein Candy"
          metaDesc="Bij Captain Candy bieden we zoetwaren van de beste kwaliteit. U vindt er heerlijke geleien, diverse chocolaatjes, heerlijke dropsnoepjes, marshmallows, maar ook kauwgom en lolly's. Er is iets voor iedereen."
        />
        <Faq dataProcessing={dataProcessing[lang].parag} title={dataProcessing[lang].title} />
    </div>
  )
}

export default PersonalDataProcessingPage;

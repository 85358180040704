import React from "react";
import hpBox from "../img/hp_box_1-min.jpg";
import hpBox1 from "../img/hp_box_2-min.jpg";
import hpBox2 from "../img/hp_box_3-min.jpg";
import hpBox3 from "../img/hp_box_4-min.jpg";

const boxes = {
    title: {
        en: "From Captain Candy’s board",
        nl: "Op het piratenschip van Captain Cindy",
    },
    en: [
        {
            img: hpBox,
            title: <React.Fragment> Can you smell the sweetness in the air?</React.Fragment>,
            description: "Temptation at its best. Discover this world of sweets with us.",
            link: "More information",
            href: "/products",
            longer: true,
        },
        {
            img: hpBox1,
            title: <React.Fragment>All the places we anchor</React.Fragment>,
            description: "You can now find us in two places in Amsterdam!",
            link: "More information",
            href: "/stores"
        },
        {
            img: hpBox2,
            title: <React.Fragment>The end of sour looks!</React.Fragment>,
            description: "Do people give you sour looks? Try offering them our sour jelly beans!",
            link: "More information",
            href: "/captain-story",
            longer: true,
        },
        {
            img: hpBox3,
            title: <React.Fragment>Find out more about what we do</React.Fragment>,
            description: "Discover the journey of a Czech company that brings joy from all over the world.",
            link: "More information",
            href: "/about"
        },
    ],
    nl: [
        {
            img: hpBox,
            title: <React.Fragment>Ruik jij dat ook? </React.Fragment>,
            description: "De zoete geur die door de lucht waait! Verleiding op zijn best. Zet koers met ons naar de wereld van snoep",
            link: "Meer informatie",
            href: "/products",
            longer: true,
        },
        {
            img: hpBox1,
            title: <React.Fragment>Onze veroveringen </React.Fragment>,
            description: "We hebben de oceaan doorklieft en menig plekken in Europa veroverd!",
            link: "Meer informatie",
            href: "/stores"
        },
        {
            img: hpBox2,
            title: <React.Fragment>Bokkige blikken?</React.Fragment>,
            description: "Tijd voor onze zure jelly beans. Alle donders! Dit is het einde van kwade smoelen",
            link: "Meer informatie",
            href: "/captain-story",
            longer: true,
        },
        {
            img: hpBox3,
            title: <React.Fragment>Onze koers<br /></React.Fragment>,
            description: "Wat ooit begon in Tjechië, heeft de wind ons nu verder gebracht over de wereld. De lekkerste schatten ontdekt, voor jou!",
            link: "Meer informatie",
            href: "/about"
        },
    ],
};

const HpMapData = {
    en: {
        title: "Our store in Amsterdam",
        button: {
            name: "More information",
        }
    },
    nl: {
        title: "Onze winkels in Amsterdam",
        button: {
            name: "Alle winkels",
        }
    },
};
    
export { boxes, HpMapData };
    
import novinkyNaEmailEn from "../img/novinky-na-email_en.svg";
import FlessenpostOntvangen from "../img/Flessenpost-ontvangen.svg";
import { Fragment } from "react";

const NewsLetterContent = {
    en: {
        img: novinkyNaEmailEn,
        title: <Fragment>Hopefully you don't see this as a threat, but if you don't confirm your registration, we'll be allowed to
        Royal Fleet not befriend you...<br />
        It's just one step – check your email box and confirm the message.<br />
        Thank you. We wish you lots of wind in the right direction.</Fragment>,
        placeholder: "Enter your e-mail address",
        buttonName: "Sing Up",
        error: <Fragment>Dust and bullets, someone has already entered this email before you! <br />Enter another email, please.</Fragment>,
        emailExist: <Fragment>Confound it, this e-mail was entered by somebody before you! <br />Enter another e-mail, please.</Fragment>,
    },
    nl: {
        img: FlessenpostOntvangen,
        title: "Voorheen krasten we onze berichten op perkament en stuurden we ze in flessen de oceaan over. Gelukkig hebben we nu e-mail aan boord! Voer je email-adres in en wees als eerste op de hoogte van de laatste expedities en schatten van de kapitein.",
        placeholder: "Voer je e-mailadres in",
        buttonName: "Aanmelden",
        error: <Fragment>Stof en kogels, iemand heeft deze e-mail al eerder ingevoerd dan jij! <br />Voer een ander e-mailadres in, alstublieft.</Fragment>,
        emailExist: <Fragment>Dit e-mailadres is al bij ons bekend! Je hoeft nu niks te doen. Of voer een ander e-mail adres in.</Fragment>
    },
};
const NewsLetterContentFaq = {
    en: {
        img: novinkyNaEmailEn,
        title: "Up until a few years back, our messages would have been etched on parchment and sent out in bottles. Fortunately we have e-mail these days. Write your address on the sheet to be the first to find out about all the captain’s expeditions and treasures.",
        placeholder: "Enter your e-mail address",
        buttonName: "Sing Up",
        error: <Fragment>Dust and bullets, someone has already entered this email before you! <br />Enter another email, please.</Fragment>,
    },
    nl: {
        img: FlessenpostOntvangen,
        title: <Fragment>
                Hopelijk ziet je dit niet als een bedreiging maar als je je inschrijving niet bevestigt, mogen we van de
                Royal Fleet geen vriendschap met je afsluiten...<br />
                Het is maar één stap – check je e-mailkist en bevestig het bericht.<br />
                Bedankt. We wensen je veel wind in de juiste richting.
            </Fragment>,
        placeholder: "Voer je e-mailadres in",
        buttonName: "Verzonden",
        error: <Fragment>Stof en kogels, iemand heeft deze e-mail al eerder ingevoerd dan jij! <br />Voer een ander e-mailadres in, alstublieft.</Fragment>,
    },
};

export {
    NewsLetterContent,
    NewsLetterContentFaq,
};
import { Fragment } from "react";
import { Link } from "react-router-dom";
const toTop = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
};

const faqData = {
    title: {
        en: "Frequently asked questions",
        nl: "Veelgestelde vragen",
        de: "Häufig gestellte Fragen",
    },
    en: [
    	{
            id: "1",
            title: " Where do the sweets in Captain Candy’s stores come from?", 
            content: "Captain Candy’s ships travel all over Europe (from Spain to Germany, from Sweden to Italy) to the very best suppliers for candy. All you have to do is look at the barrel or the box and that will tell you where the particular candy has sailed from."
        },
        {
            id: "2",
            title: " What is the shelf life of the candy?", 
            content: "As we pirates say, “You can find the answer to everything in a barrel”. In this case not actually in the barrel, but on the barrel. The candy’s shelf life is written on each of them.",
        },
        {
            id: "3",
            title: "How should the candy be stored after it has been bought?", 
            content: "In the same way as supplies on pirate expeditions - if possible in dry and cold conditions",
        },
        {
            id: "4",
            title: "Is it okay that the candy in the stores lies in open barrels?", 
            content: "Although pirates often lived outside the law, everything’s all right here. It’s called “free sale” and doesn’t break hygiene rules in any way. It’s like when you put bread in a bag in a port’s “super” market…",
        },
        // {
        //     id: "5",
        //     title: "Where does Captain Candy come from?", 
        //     content: "The first Captain Candy store was opened in Prague and after this success we decided to expand further. You can currently find us in several countries and we are planning more!",
        // },
        {
            id: "6",
            title: "Why can’t we try the candy in the barrels?", 
            content: "Unfortunately, it’s not possible for hygiene reasons. But don’t be afraid to ask our crews in the stores – they will be happy to help you find the exact taste you are looking for.",
        },
        {
            id: "7",
            title: "Is it hygienic to put tongs on barrels? ", 
            content: "The old pirate rule states that “tongs belong on a barrel”. What’s more, here too we respect the law which doesn’t see any problem with this.",
        },
        {
            id: "9",
            title: "Does the Captain Candy concept also work as a franchise?", 
            content: 'Yes, the Captain Candy concept successfully operates several franchise stores. If you are interested in cooperation, please contact us via the form in the "Franchising" section.',
        },
        {
            id: "10",
            title: "Are the barrels really completely full?", 
            content: "Pirates must have some secrets. And that’s why we’ll leave it to you to guess.",
        },
        {
            id: "11",
            title: "Which is the most popular candy?", 
            content: "Not even the famous sorceress from the Island of Crystal Balls could answer that. Everyone chooses whatever suits their taste and the best-selling candy often changes. If you look at the barrels in the stores you will find out more.",
        },
        {
            id: "12",
            title: "What are the stores’ opening hours?", 
            content: <Fragment>From Monday to Friday we take in every traveller from 10 in the morning till 11 at night. At weekends between 10 o’clock in the morning and midnight. You can look in detail here. <Link onClick={() => toTop()} to="/stores">here</Link>.</Fragment>,
        },
        {
            id: "13",
            title: " Why isn’t the price of the candy written on each barrel?", 
            content: " Because there’s no need. ;) The price per 100 g is uniform for the entire selection and you will always find it written in the store.",
        },
    ],
    nl: [
    	{
            id: "1",
            title: "Waar komen de snoepjes in Captain Candy winkels vandaan?", 
            content: "De schepen van Captain Candy halen de snoepjes bij de beste leveranciers door heel Europa (van Spanje tot Duitsland, van Zweden tot Italië). Één blik op het vat of de doos en je weet al waar het snoep vandaan komt.",
        },
    	{
            id: "2",
            title: "Wat is de houdbaarheid van de snoep?", 
            content: "Zoals wij piraten zeggen: “Antwoord op al je vragen vind je in het vat.” In dit geval niet in het vat maar op het vat. Op elk vat staat de houdbaarheidsdatum van de snoepjes vermeld.",
        },
    	{
            id: "3",
            title: "Hoe moeten snoepjes na aankoop worden bewaard?", 
            content: "Zoals voorraad tijdens piratenexpedities - bij voorkeur droog en koud.",
        },
    	{
            id: "4",
            title: "Mag snoep in winkels op open vaten liggen?", 
            content: 'Hoewel piraten vaak boven de wet staan, is dit helemaal in orde. Het gaat hier om ‘de vrije verkoop’ en deze is op geen enkele manier in strijd met de hygiënische normen. Het is vergelijkbaar met het kopen van brood op de havenmarkt….',
        },
    	// {
        //     id: "5",
        //     title: "Waar komt Kapitein Candy vandaan?", 
        //     content: "De eerste Captain Candy winkel werd geopend in Praag en na dit succes besloten we verder uit te breiden. U kunt ons momenteel in verschillende landen vinden en we plannen meer!",
        // },
    	{
            id: "6",
            title: "Waarom mag ik geen snoepjes uit de vaten proeven?", 
            content: "Helaas is dit om hygiënische redenen niet mogelijk. Maar wees niet bang om onze bemanning in de winkels te vragen - ze helpen je graag de juiste smaak te vinden die je zoekt.",
        },
    	{
            id: "7",
            title: "Is het hygiënisch om de tang op het vat te leggen?", 
            content: "Zoals de oude piratenregel voorschrijft: “De tang hoort op het vat te liggen”. Bovendien respecteren we ook de wet, die in deze handeling geen probleem ziet.",
        },
    	{
            id: "9",
            title: "Werkt het Captain Candy-concept als een franchise? ", 
            content: 'Ja, het Captain Candy-concept exploiteert met succes verschillende franchisewinkels. Als u geïnteresseerd bent in samenwerking, neem dan contact met ons op via het formulier in de sectie "Franchising".',
        },
    	{
            id: "10",
            title: "Zijn de vaten echt helemaal vol?", 
            content: <Fragment>Piraten moeten ook een aantal geheimen hebben. En dit is er één van, het best bewaarde piratengeheim 😉</Fragment>,
        },
    	{
            id: "11",
            title: "Welk snoepje is het populairst?", 
            content: "Zelfs de bekende tovenaar van het Crystal Ball Island kan daar geen antwoord op geven. Iedereen kiest volgens zijn/haar smaak en het best verkochte snoepje verandert vaak door het jaar heen. Meer informatie vind je op de vaten in de winkel.",
        },
    	{
            id: "12",
            title: "Wat zijn de openingstijden van de winkels?", 
            content: <Fragment>Elke winkel hanteert zijn eigen piratenregels. Meer details vind je <Link to="/stores" onClick={() => toTop()}>hier.</Link></Fragment>,
        },
    	{
            id: "13",
            title: "Waarom staat er geen prijs op elk vat?", 
            content: "Omdat het niet nodig is ;) De prijs per 100 gram is gelijk voor het hele assortiment en staat altijd vermeld in de winkel.",
        },
    ],
};

const dataProcessing = {
    en: {
        title: "Personal data processing",
        parag: [
            'In order to protect personal data, I agree, as a personal data subject, to the collection, storage and processing of the following personal data: name, surname, e-mail address, residential address, date of birth, telephone number mobile phone, information on educational qualifications and other information voluntarily provided in the questionnaire sent, by the owner of the personal data, namely: Tresher s.r.o., registered office: Prague - Na Maninách 1040/14, Holešovice (Prague 7), 170 00 Prague, tax code 24217280 , VAT number CZ24217280, registered at the Court of Prague, deeds no. 189598 C ("owner").',
            "This consent is granted for an indefinite period until the subject withdraws his/her consent.",
            "The purpose of the processing of the personal data of the interested party established by the owner is to use the personal data only for the possible establishment of a commercial cooperation with the interested party.",
            "The owner declares that he will collect personal data to the extent necessary for the fulfillment of the aforementioned purposes and that he will only process them in accordance with the purpose for which they were collected. The holder declares that the personal data will be processed in the following way: a) mechanically (automatically), by computer and software within the information system; b) manually.",
            "Furthermore, I declare that I have been informed and know the owner's privacy policy."
        ]
    },
    nl: {
        title: "Verwerking van persoonsgegevens",
        parag: [
            'In het kader van de bescherming van persoonsgegevens ga ik akkoord met het verzamelen, opslaan en verwerken van mijn persoonsgegevens: voornaam, achternaam, e-mailadres, woonadres, geboortedatum, mobiel telefoonnummer, informatie over mijn h oogst genoten opleiding en overige gegevens die ik vrijwillig heb ingevuld in het verzonden formulier. En geef ik bij deze toestemming aan de beheerder van deze persoonsgegevens, namelijk: Tresher s.r.o., gevestigd in Praag - Na Maninách 1040/14, Holešovice (Praag 7), 170 00 Praag, KvK 24217280, BTW nr. CZ24217280, geregistreerd bij de gemeentelijke rechtbank in Praag, dossiernummer 189598 C. (hierna te noemen “de beheerder’’).',
            "Ik verleen deze toestemming voor onbepaalde tijd, tenzij ik mijn toestemming intrek. Het door de beheerder gespecificeerde doel van de verwerking van persoonsgegevens, waarvoor ik toestemming heb gegeven, is het gebruik van persoonsgegevens alleen voor het eventueel tot stand brengen van zakelijke samenwerking met mij.",
            "De beheerder verklaart dat hij persoonsgegevens zal verzamelen voor zover nodig is om het hierboven genoemde doel te bereiken en deze verwerken uitsluitend overeenkomstig het doel waarvoor ze zijn verzameld. De beheerder verklaart persoonsgegevens op de volgende wijze te verwerken: a) machinaal (geautomatiseerd), via computers en computerprogramma’s binnen het informatiesysteem & b) handmatig.",
            "Deze toestemming is een vrije en bewuste uitdrukking van mijn wil, waarvan de inhoud is de toestemming van mijn persoon als een betrokken subject bij de verwerking van de bovengenoemde persoonsgegevens. Ik begrijp dat ik deze toestemming op elk moment kan intrekken. Hiermee vervalt echter niet het recht van de beheerder (of verwerker) om persoonsgegevens te verwerken, indien het gebruik daarvan noodzakelijk is voor de dienstverlening, respectievelijk voor de uitvoering van het contract en voor zover wettelijk toegestaan.",
            "Verder bevestig ik dat ik geïnformeerd ben over en bekend ben met het privacy beleid en de verwerking van persoonsgegevens van de beheerder.",
        ]
    },
}

export {
    faqData,
    dataProcessing,
};
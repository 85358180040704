import { Fragment } from "react";
import firsPage from "../img/captain/jak_kapitan.png";
import firsPageNl from "../img/captain/jak_kapitan_nl.png";
import firsPageLod from "../img/captain/lod.png";
import hledaniVecneho from "../img/captain/hledani_vecneho.png";
import hledaniVecnehoNl from "../img/captain/hledani_vecneho_nl.png";
import pergamen from "../img/captain/pergamen.png";
import mladi from "../img/captain/mladi.png";
import mladiNL from "../img/captain/mladi_nl.png";
import vaha from "../img/captain/vaha.png";
import mapa from "../img/captain/mapa.png";
import obchodnik from "../img/captain/obchodnik.png";
import najdete_i_vy_svuj_pokladNl from "../img/captain/najdete_i_vy_svuj_poklad_nl.png";
import najdete_i_vy_svuj_poklad from "../img/captain/najdete_i_vy_svuj_poklad.png";
import najdete_i_vy_svuj_pokladHover from "../img/captain/najdete_i_vy_svuj_poklad-hover.png";


const CaptainStoryData = {
    en: [
        {
            data:  <Fragment>
                        <div className="item one w-100 h-100">
                            <img src={firsPage} alt="How the Captain found his true treasure" />
                        </div>
                    </Fragment>,
        },
        {
            data:  <Fragment>
                        <div className="item two w-100 h-100">
                            <img src={firsPageLod} alt="loď" className="hidden-xs" />
                            <div className="text">
                                <p>
                                    Nothing in the world could scare him. Nothing could deter him from his looting and plundering.
                                </p>
                                <img src={firsPageLod} alt="loď" className="visible-xs" />
                                <p>
                                    There was only thing that the Captain was afraid of. He was afraid of getting old.  And he was afraid that his famous pieces would one day be forgotten.
                                </p>
                            </div>
                        </div>
                    </Fragment>,
        },
        {
            data:   <Fragment>
                        <div className="item three w-100 h-100">
                            <img src={hledaniVecneho} alt="So he became.." className="hidden-xs" />
                            <img src={hledaniVecneho} alt="So he became.." className="visible-xs" style={{ marginTop: "20px !important" }} />
                            <img src={pergamen} alt="pergamen" className="hidden-xs imgCustomStyle" style={{ marginTop: "-2.5vw !important", width: "70% !important",  marginLeft: "15% !important" }} />
                        </div>
                    </Fragment>,
        },
        {
            data:   <Fragment>
                        <div className="item four w-100 h-100">
                            <img src={mladi} alt="..obsessed with the search for eternal youth." className="hidden-xs" />
                            <img src={mladi} alt="..obsessed with the search for eternal youth." className="visible-xs m-t--40" />
                            <img src={pergamen} alt="parchments" className="visible-xs no-margin" />
                        </div>
                    </Fragment>,
        },
        {
            data:   <Fragment>
                        <div className="item five w-100 h-100">
                            <img src={vaha} alt="weighing-machine" className="hidden-xs" />
                            <div className="text">
                                <p>
                                    He would appear as a spectre
                                </p>
                                <p>
                                    He would pillage, kidnap, interrogate…
                                </p>
                                <p>
                                    He wasn’t looking for treasure like everyone else. He wasn’t interested in gold, he wasn’t interested in precious metals, he wasn’t interested in diamonds.
                                </p>
                                <p>
                                He was looking for the elixir of eternal youth.
                                </p>
                            </div>
                            <img src={vaha} alt="weighing-machine" className="visible-xs" style={{ marginBottom: "15px !important" }} />
                        </div>
                    </Fragment>,
        },
        {
            data:   <Fragment>
                        <div className="item six w-100 h-100">
                            <div className="text">
                                <p>
                                    In an effort to save their lives, his prisoners sent him to islands that were not shown on nautical charts, to priestesses about whom only the very darkest legends were told, to look for plants shown only on the oldest parchments. But none of this advice concealed the recipe for eternal youth.
                                </p>
                            </div>
                            <img src={mapa} alt="map" className="hidden-xs" />
                            <img src={mapa} alt="map" className="visible-xs" style={{ marginTop: "40px !important", marginBottom: "25px !important" }} />
                        </div>
                    </Fragment>
        },
        {
            data:   <Fragment>
                        <div className="item seven w-100 h-100">
                            <div className="text">
                                <p>
                                It was only in one of the towns he travelled to that an old man, a merchant, fell into the hands of the Captain. Unlike the others he didn’t think up some fairy story. Instead, he took the Captain to his home and offered him a small, round, shiny sweet.
                                </p>
                            </div>
                            <img src={obchodnik} alt="Trader" className="hidden-xs" />
                            <img src={obchodnik} alt="Trader" className="visible-xs" style={{ marginTop: "45px !important", marginBottom: "20px !important" }} />
                        </div>
                    </Fragment>
        },
        {
            data:   <Fragment>
                        <div className="item eight w-100 h-100">
                            <div className="text">
                                <p>
                                    The taste of this tiny morsel took the Captain back to his childhood. And he understood. <br /> You can’t stay young forever.<br /> But anyone can go back to their youth for a little while!
                                </p>
                            </div>
                            <div className="img-wrap">
                                <a href="/products" className="relative">
                                    <img src={najdete_i_vy_svuj_poklad} alt="Find your treasure too!" />
                                    <img src={najdete_i_vy_svuj_pokladHover} className="hover" alt="Find your treasure too!" />
                                </a>
                            </div>
                        </div>
                    </Fragment>
        },
    ],
    nl: [
        {
            data:  <Fragment>
                        <div className="item one w-100 h-100">
                            <img src={firsPageNl} alt="Hoe de kapitein zijn echte schat vond" />
                        </div>
                    </Fragment>,
        },
        {
            data:  <Fragment>
                        <div className="item two w-100 h-100">
                            <img src={firsPageLod} alt="loď" className="hidden-xs" />
                            <div className="text">
                                <p>
                                    Niemand kon hem laten terugdeinzen. Een leven vol roven en plunderen. Niets kon hem tegenhouden, behalve één ding: ouder worden.
                                </p>
                                <img src={firsPageLod} alt="loď" className="visible-xs" />
                                <p>
                                    De kapitein was bang om te sterven en zijn daarmee zijn beruchte daden op een dag in de vergetelheid zouden raken.
                                </p>
                            </div>
                        </div>
                    </Fragment>,
        },
        {
            data:   <Fragment>
                        <div className="item three w-100 h-100">
                            <img src={hledaniVecnehoNl} alt="So he became.." className="hidden-xs" />
                            <img src={hledaniVecnehoNl} alt="So he became.." className="visible-xs" style={{ marginTop: "20px !important" }} />
                            <img src={pergamen} alt="pergamen" className="hidden-xs imgCustomStyle" style={{ marginTop: "-2.5vw !important", width: "70% !important",  marginLeft: "15% !important" }} />
                        </div>
                    </Fragment>,
        },
        {
            data:   <Fragment>
                        <div className="item four w-100 h-100">
                            <img src={mladiNL} alt="..Met de zoektocht naar de eeuwige jeugd." className="hidden-xs" />
                            <img src={mladiNL} alt="..Met de zoektocht naar de eeuwige jeugd." className="visible-xs m-t--40" />
                            <img src={pergamen} alt="parchments" className="visible-xs no-margin" />
                        </div>
                    </Fragment>,
        },
        {
            data:   <Fragment>
                        <div className="item five w-100 h-100">
                            <img src={vaha} alt="weighing-machine" className="hidden-xs" />
                            <div className="text">
                                <p>
                                    Hij dook op als een schim.
                                </p>  
                                <p>
                                     Niets of niemand was veilig voor zijn plunderingen, kidnappings en verhoren.<br /> Hij was niet op zoek naar goud of andere edelmetalen, noch diamanten. Nee, zijn missie was veel groter dan dat. Vastberaden om het geheim van de eeuwige jeugd te vinden.
                                </p>
                            </div>
                            <img src={vaha} alt="weighing-machine" className="visible-xs" style={{ marginBottom: "15px !important" }} />
                        </div>
                    </Fragment>,
        },
        {
            data:   <Fragment>
                    <div className="item six w-100 h-100">
                        <div className="text">
                            <p>
                                Om hun eigen leven te sparen, stuurden zijn gevangenen hem naar eilanden die niet op zeekaarten stonden. <br /> Naar mysterieuze priesteressen uit duistere legendes en zocht naar planten die enkel op een eeuwenoud perkament stonden afgebeeld. Maar helaas, geen enkel spoor van de eeuwige jeugd	
                            </p>
                        </div>
                        <img src={mapa} alt="map" className="hidden-xs" />
                        <img src={mapa} alt="map" className="visible-xs" style={{ marginTop: "40px !important", marginBottom: "25px !important" }} />
                    </div>
                </Fragment>
        },
        {
            data:   <Fragment>
                    <div className="item seven w-100 h-100">
                        <div className="text">
                            <p>
                                Na talloze reizen kwam de kapitein aan in een kleine stad. Daar op de markt, trok een koopman hem zijn aandacht. Deze oude man vroeg de kapitein hem te volgen. Daar in zijn huis bood hij hem een klein, rond, glanzend snoepje aan.     
                            </p>
                        </div>
                        <img src={obchodnik} alt="Trader" className="hidden-xs" />
                        <img src={obchodnik} alt="Trader" className="visible-xs" style={{ marginTop: "45px !important", marginBottom: "20px !important" }} />
                    </div>
                </Fragment>
        },
        {
            data:   <Fragment>
                    <div className="item eight w-100 h-100">
                        <div className="text">
                            <p>
                                De smaak deed hem aan iets denken…. het bracht hem terug naar zijn kindertijd!  <br />  Toen begreep hij het.  <br /> Je kunt niet voor altijd jong blijven, maar je kunt wel voor even terugkeren naar je jeugd!
                            </p>
                        </div>
                        <div className="img-wrap">
                            <a href="/products" className="relative">
                                <img src={najdete_i_vy_svuj_pokladNl} alt="Find your treasure too!" />
                                <img src={najdete_i_vy_svuj_pokladHover} className="hover" alt="Find your treasure too!" />
                            </a>
                        </div>
                    </div>
                </Fragment>
        },
    ],
};

export {
    CaptainStoryData,
};
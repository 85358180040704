import React from 'react'
import { Link } from 'react-router-dom'
import MySvgIcon from './MySvgIcon'
import { footerMenuSocial } from '../../assets/dummyData/Menu';
import followUs from "../../assets/img/followus.svg";
import followUsDe from "../../assets/img/followus-nl.svg";

const Social = ({ classProp, lang }) => {
  const openLink = (url) => {
    const ridirectLink = url || "https://captaincandy.eu/"
    window.open(ridirectLink, '_blank');
  };

  return (
    <div className={`${classProp}`}>
      <img src={lang === "en" ? followUs : followUsDe} alt="Captain Candy" className="follow-us" /><br/>
      <ul className={`wrapper-icon social-links`}>
        {footerMenuSocial.map((el) => (
          <li
            key={el.path}
            className={`${el.iconName} icon`}
          >
            <span class="tooltip-icon">{el.name}</span>
            <Link onClick={() => openLink(el.path)}
              className={`${el.icon}`}
            >
              <MySvgIcon icon={el.icon} />
              <span></span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Social;

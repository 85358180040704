import { Fragment } from "react";
import { Link } from "react-router-dom";
const toTop = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
};

const career = {
    en: {
        title: "Jobs currently available with us",
        accordionTitle: "Sales assistant",
        accordionSubTitle: "Set off on a pirate’s adventure with help and us other people find their personal treasure trove! The world can never have enough reliable sailors on our ship and we’re prepared to pay them their weight in gold!",
        accordion: [
            {
                id: "1",
                title: "Sales assistant",
                content:  "Set off on a pirate’s adventure with help and us other people find their personal treasure trove! The world can never have enough reliable sailors on our ship and we’re prepared to pay them their weight in gold!",
                item: [
                    {
                        title: "Why sail with us?",
                        list: [
                            "Our boat is anchored right in the heart of Amsterdam",
                            "No matter how big the wave, we can’t be overturned, we’re a stable Czech company",
                            "Our sailors get to work flexible shifts",
                            "There’s a friendly atmosphere among members of the crew ",
                            "We offer fair and motivating pay",
                            "Opportunity to earn extra pay as part of our bonus programme",
                            "Opportunities to climb the career ladder with us – from sailor all the way to admiral",
                            "Get to choose the length of your expedition  - full or part-time hours",
                            "We’ll train you up on everything you need to know to become a fully-fledged crew member",
                        ]
                    },
                    {
                        title: "What are our expectations once you’re on board?",
                        list: [
                            "Friendly and positive behaviour when communicating with the natives as well as foreigners",
                            "Willingness to learn new skills",
                            "Flexibility to weather all storms",
                            "To work with zeal and have a healthy work ethic.",
                        ]
                    },
                    {
                        title: "What kind of work awaits our eager new recruits?",
                        list: [
                            "To help and serve everyone the tide brings in",
                            "To keep the trunks and barrels fully stocked with sweet treats",
                            "To pop down to the lower decks to pick up supplies",
                            "To scrub the decks and captain’s bridge every now and then after a hard day’s work",
                        ],
                    },
                ],
                itemFooter: "If you’re interested and keen to get on board with us, be ready to set sail right away!",
                table: <Fragment>
                        <table>
                            <thead><tr><td></td><td></td></tr></thead>
                            <tbody>
                                <tr>
                                    <td>E-mail:</td>
                                    <td>
                                        <a href="mailto:info.nl@captaincandy.eu"><b>info.nl@captaincandy.eu</b></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Mobile:</td>
                                    <td><a href="tel:+310681733111"><b>+31 (0) 6 81 73 31 11</b></a></td>
                                </tr>
                                <tr>
                                    <td>Phone number:</td>
                                    <td><a href="tel:+31202268319"><b>+31 20 226 83 19</b></a></td>
                                </tr>
                            </tbody>
                        </table>
                    </Fragment>
            },
        ],
        itemFooter: "If you’re interested and keen to get on board with us, be ready to set sail right away!",
        inputs: [
            {
                id: "contactform_fullName",
                name: "contactform_fullName",
                label: "Name and surname",
            },
            {
                id: "contactform_email",
                name: "contactform_email",
                label: "E-mail",
            },
            {
                id: "contactform_phone",
                name: "contactform_phone",
                label: "Telephone number",
                class: "inputphone",
                style: { display: "inline-block" },
            },
            {
                id: "contactform_text",
                name: "contactform_text",
                label: "Covering letter",
                textarea: true,
            },
            {
                id: "file",
                name: "cv",
                label: "CV",
                placeholder: "Browser",
                file: true,
            },
        ],
        link: <Fragment>“I consent to the <Link onClick={() => toTop()} to="/personal-data-processing" className="link">processing of my personal data</Link> and the responses given in the questionnaire.</Fragment>,
        button: "Send message"
    },
    nl: {
        title: "Actuele vacatures",
        accordionTitle: "Sales assistent",
        accordionSubTitle: "Ga met ons mee op een piratenavontuur en help anderen de weg naar hun eigen schat te vinden! We hebben nooit genoeg betrouwbare matrozen, je goede dienst tijdens de vaartocht wordt met goud beloond!",
        accordion: [
            {
                id: "1",
                title: "Sales assistent",
                content:  "Ga met ons mee op een piratenavontuur en help anderen de weg naar hun eigen schat te vinden! We hebben nooit genoeg betrouwbare matrozen, je goede dienst tijdens de vaartocht wordt met goud beloond!",
                item: [
                    {
                        title: "Waarom juist met ons?",
                        list: [
                            "Ons schip ligt afgemeerd in het hart van Amsterdam",
                            "Geen enkele golf zal ons omverwerpen, we zijn een stabiel Tsjechisch bedrijf",
                            "De werktijden van onze matrozen zijn flexibel",
                            "Er heerst een vriendelijke sfeer tussen de bemanning",
                            "Je krijgt een motiverende en eerlijke beloning",
                            "We bieden extra beloning binnen het bonusprogramma",
                            "Mogelijke carrièreontwikkeling – van bootsman tot admiraal",
                            "Bepaal zelf de lengte van je vaartocht – voltijd- of deeltijdbaan",
                            "Elk bemanningslid wordt eerst goed opgeleid",
                        ],
                    },
                    {
                        title: "Wat zijn onze scheepsregels?",
                        list: [
                            "Prettig en positief gedrag in de omgang met de lokale bevolking en buitenlanders",
                            "Bereidheid om nieuwe dingen te leren",
                            "Tijdflexibiliteit om alle valkuilen tijdens de vaartocht te beheren",
                            "Werk met enthousiasme en gezonde werkbetrokkenheid.",
                        ]
                    },
                    {
                        title: "Wat voor werk kan elk bemanningslid bij ons verwachten?",
                        list: [
                            "Je helpt en bedient iedereen die door de golven aan boord wordt aangespoeld",
                            "Je houdt de vaten en kisten vol met zoete schatten",
                            "Je haalt de voorraden in het magazijn benedendeks",
                            "Soms moet je na een zware dag het dek en de kapiteinsbrug schrobben",
                        ],
                    },
                ],
                itemFooter: "Als je in zee met ons gaat, kunnen we meteen vertrekken!",
                table: <Fragment>
                            <tbody>
                                <tr>
                                    <td>E-mail:</td>
                                    <td>
                                        <a href="mailto:info.nl@captaincandy.eu"><b>info.nl@captaincandy.eu</b></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Mobiel:</td>
                                    <td><a href="tel:+310681733111"><b>+31 (0) 6 81 73 31 11</b></a></td>
                                </tr>
                                <tr>
                                    <td>Telefoonnummer:</td>
                                    <td><a href="tel:+31202268319"><b>+31 20 226 83 19</b></a></td>
                                </tr>
                            </tbody>
                    </Fragment>
            },
        ],
        itemFooter: "Wil je met ons in zee gaan? We zijn klaar om te vertrekken!",
        inputs: [
            {
                id: "contactform_fullName",
                name: "contactform_fullName",
                label: "Voornaam en achternaam",
            },
            {
                id: "contactform_email",
                name: "contactform_email",
                label: "E-mail",
            },
            {
                id: "contactform_phone",
                name: "contactform_phone",
                label: "Telefoonnummer",
                class: "inputphone",
                style: { display: "inline-block" },
            },
            {
                id: "contactform_text",
                name: "contactform_text",
                label: "Sollicitatiebrief",
                textarea: true,
            },
            {
                id: "file",
                name: "cv",
                label: "CV",
                placeholder: "Bladeren",
                file: true,
            },
        ],
        link: <Fragment>“Ik ga akkoord met de  <Link  onClick={() => toTop()} to="/personal-data-processing" className="link">verwerking van persoonsgegevens</Link></Fragment>,
        button: "Verzenden"
    },
    de: {
        title: "Aktuell offene Stellen",
        accordionTitle: "Verkäufer/in",
        accordionSubTitle: "Set off on a pirate’s adventure with help and us other people find their personal treasure trove! The world can never have enough reliable sailors on our ship and we’re prepared to pay them their weight in gold!",
        accordion: [
            {
                id: "1",
                title: "Verkäufer/in",
                content:  "Segeln Sie mit uns auf ein Piraten-Abenteuer und helfen Sie anderen Ihren Weg zu ihrem eigenen Schatz zu finden! Es gibt niemals genug zuverlässige Segler und wir belohnen ehrliche Arbeit mit Gold während der Reise!",
                item: [
                    {
                        title: "Warum mit uns segeln?",
                        list: [
                            "Unser Schiff ankert im Herzen von Prag",
                            "Keine Welle wird uns überrollen, wir sind eine stabile tschechische Gesellschaft",
                            "Die Arbeitszeiten des Matrosen sind bei uns flexibel",
                            "Es herrscht eine freundliche Atmosphäre zwischen der Besatzung",
                            "Wir sorgen für eine motivierende und faire Bezahlung",
                            "Extra-Sold innerhalb des Bonusprogramms",
                            "Wir bieten einen Karrierewechsel - vom Matrosen zum Admiral",
                            "Wählen Sie die Länge des Segeltörns – Voll- oder Teilzeit",
                            "Wir schulen Sie in allem, was für die Schiffscrew benötigt wird",
                        ],
                    },
                    {
                        title: "Welche Schiffbaustandards benötigen wir?",
                        list: [
                            "Ein angenehmes und positives Verhalten im Umgang mit Einheimischen und Ausländer*innen",
                            "Die Bereitschaft, Neues zu lernen",
                            "Eine zeitliche Flexibilität, um alle Fallstricke des Segelns zu bewältigen",
                            'Sie sollten „brennen“ für Ihre Arbeit und einen gesunden Arbeitseinsatz leisten.',
                        ]
                    },
                    {
                        title: "Welche Arbeit erwartet die Seewölfe?",
                        list: [
                            "Jedem zu helfen und zu bedienen, der von den Wellen an Bord getragen wird",
                            "Halten Sie Truhen und Fässer voller süßer Schätze",
                            "Laufen Sie schnell, um Proviant unter Deck zu lagern",
                            "Schrubben Sie nach einem anstrengenden Tag hier und da das Deck und die Kapitänsbrücke",
                        ],
                    },
                ],
                itemFooter: "Wenn Sie Interesse haben, mit uns kommen, können wir jetzt ablegen!",
                table: <Fragment>
                            <tbody>
                                <tr>
                                    <td>E-mail:</td>
                                    <td>
                                        <a href="mailto:info.nl@captaincandy.eu"><b>info.nl@captaincandy.eu</b></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Mobiltelefon:</td>
                                    <td><a href="tel:+310681733111"><b>+31 (0) 6 81 73 31 11</b></a></td>
                                </tr>
                                <tr>
                                    <td>Telefonnummer:</td>
                                    <td><a href="tel:+31202268319"><b>+31 20 226 83 19</b></a></td>
                                </tr>
                            </tbody>
                    </Fragment>
            },
        ],
        itemFooter: "Wenn Sie interessiert sind und bei uns einsteigen, sollten Sie wissen, dass wir sofort ablegen!",
        inputs: [
            {
                id: "contactform_fullName",
                name: "contactform_fullName",
                label: "Name und Nachname",
            },
            {
                id: "contactform_email",
                name: "contactform_email",
                label: "E-mail",
            },
            {
                id: "contactform_phone",
                name: "contactform_phone",
                label: "Telefon",
                class: "inputphone",
                style: { display: "inline-block" },
            },
            {
                id: "contactform_text",
                name: "contactform_text",
                label: "Motivationsschreiben",
                textarea: true,
            },
            {
                id: "cv",
                name: "cv",
                label: "Lebenslauf",
                placeholder: "Datei auswählen",
                file: true,
            },
        ],
        link: <Fragment>Ich stimme der <Link onClick={() => toTop()} to="/personal-data-processing" className="link">Verarbeitung personenbezogener Daten</Link> zu.</Fragment>,
        button: "Nachricht absenden"
    },
};

export {
    career,
};
import React, { useState }from 'react';
import axios from "axios";
import { validateEmail } from '../../assets/function';
import newsletterRope from "../../assets/img/newsletter-rope.png";
import { axiosLink } from '../../axiosLink';
import LoaderMail from '../LoaderMail/LoaderMail';

const NewLetter = ({ hiddeImg, data, openModal, lang }) => {
    const [enterEmail, setEmail] = useState();
    const [hasError, setHasError] = useState();
    const [emailExist, setEmailExist] = useState(false);
    const [required, setRequired] = useState(false);
    const [disabledSend, setDisabledSend] = useState(true);
    const onchange = (e) => {
        setEmail(e.target.value);
        if (required) {
            const valid = validateEmail(e.target.value);
            if (valid) {
                setHasError(false);
            } else {
                setHasError(true);
            }
        }
    }
    const sendEmail = () => {
        const valid = validateEmail(enterEmail);
        if (valid) {
            setHasError(false);
            setRequired(false);
            setDisabledSend(false);
            axios.post(
                `${axiosLink}/mail/newsletter`,
                { email: enterEmail, state: "nl", lang: "nl" },
            ).then((data) => {
                setDisabledSend(true);
                if (data?.data?.emailExist) {
                    setEmailExist(true);
                    setTimeout(() => {
                        setEmailExist(false);
                    }, 7000);
                } else {
                    openModal();
                }
            }).catch(() => {
                setDisabledSend(true);
            })
        } else {
            setRequired(true)
            setHasError(true);
        }
    }
  return (
    <div id="newsletter" className={`${!hiddeImg && "type-2"}`}>
        {!hiddeImg && <img src={newsletterRope} alt="rope" className="hr-newsletter" />}
        <div className="container-custom">
            <div className="inner">
                <img src={data?.img} alt="Want to be first" />
                <p>
                    {data?.title}
                </p>
                <div className="component-newsletter">
                    <div className="input-wrap">
                        <div className="input-group has-feedback">
                            <input type="text" className="form-control" id="newsletter_input" onChange={onchange} placeholder={data?.placeholder} />
                            <span className="input-group-btn">
                                <button onClick={() => sendEmail()} className={`btn ${!disabledSend ? "disabled" : "btn-primary "}`} id="newsletter_btn" data-newsletter-register-btn="" disabled={!disabledSend}>{disabledSend ? data?.buttonName : <LoaderMail />}</button>
                            </span>
                        </div>
                    </div>
                    {/* <!-- IF error, ELSE open #modal-email-success bud pres JS(https://www.w3schools.com/bootstrap/bootstrap_ref_js_modal.asp) nebo pres btn viz nahore --> */}
                    <span className={`error text-red ${!hasError && "hidden"}`} data-newsletter-registered-error="">
                        {data?.error}
                    </span>
                     {/* <!-- /IF error --> */}
                    <span className={`error text-red ${!emailExist && "hidden"}`}>
                        {data?.emailExist}  
                    </span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default NewLetter;
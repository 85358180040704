import aboutMin from "../img/about/1-min.png";
import aboutMin2 from "../img/about/2-min.png";
import aboutMin3 from "../img/about/3-min.png";
import aboutMin4 from "../img/about/4-min.png";
import aboutMin5 from "../img/about/5-min.png";
import aboutMin6 from "../img/about/6-min.png";
import aboutMin7 from "../img/about/7-min.png";
import aboutMin8 from "../img/about/8-min.png";
import aboutMin9 from "../img/about/9-min.png";
import about1 from "../img/about/1.svg";
import about2 from "../img/about/2.svg";
import about3 from "../img/about/3.svg";
import about4 from "../img/about/4.svg";
import about5 from "../img/about/5.svg";
import about6 from "../img/about/6.svg";
import about7 from "../img/about/7.svg";
import about8 from "../img/about/8.svg";
import about9 from "../img/about/9.svg";
// import about10 from "../img/about/33.png";
// import about11 from "../img/about/56.png";
// import year2009 from "../img/about/2009.svg";
// import year2010 from "../img/about/2010.svg";
// import year2011 from "../img/about/2011.svg";
import year2012 from "../img/about/2012.svg";
import year2013 from "../img/about/2013.svg";
import year2014 from "../img/about/2014.svg";
// import year2015 from "../img/about/2015.svg";
import year2016 from "../img/about/2016.svg";
import year2017 from "../img/about/2017.svg";
import year2020 from "../img/about/2020.svg";
import year2021 from "../img/about/2021.svg";
import year2022 from "../img/about/2022.svg";
import year2023 from "../img/about/2023.svg";
import { Fragment } from "react";

const AboutData = {
    footerText: {
        en: "...the captain's story continues!",
        nl: <Fragment>...het verhaal van de <br className='hidden-xs' /> kapitein gaat verder!</Fragment>,
    },
    en: [
        {
            img: aboutMin,
            yearImg: year2012,
            year: "2012",
            // title: <Fragment>It's here! Nothing will ever be the <br className='hidden-xs' /> same again!</Fragment>,
            description: "It's here! Nothing will ever be the same again! The final decision has been made that Captain Candy is a great concept and that we're going big.",
        },
        {
            img: aboutMin2,
            yearImg: year2013,
            year: "2013",
            // title: <Fragment>We planned,<br className="hidden-xs" />we tested,</Fragment>,
            description: "We planned, we tested, we filled the warehouses and the dream became a reality. We set up the overall concept, company and franchise structures and we're in. ",
        },
        // {
        //     img: aboutMin3,
        //     yearImg: year2011,
        //     year: "2011",
        //     title: <Fragment>We have found  <br className="hidden-xs" /> our treasure</Fragment>,
        //     description: " Our barrels are finally full and our desire to share all these tastes can finally be fulfilled. Captain Candy has arrived!",
        // },
        {
            img: aboutMin4,
            yearImg: year2014,
            year: "2014",
            // title: <Fragment>We opened<br className="hidden-xs" /> the doors</Fragment>,
            description: "We opened the doors of our first store in Prague. We chose really carefully, and thanks to that we are able to offer our customers not only premium candies, but also a perfect experience every time they visit us.",
        },
        {
            img: aboutMin5,
            yearImg: year2016,
            year: "2016",
            // title: <Fragment>Our stores are becoming more<br className="hidden-xs" /> and more popular</Fragment>,
            description: "Our stores are becoming more and more popular with customers, so we know we have chosen the right path. We are opening more stores in Prague and preparing for international expansion.",
        },
        {
            img: aboutMin6,
            yearImg: year2017,
            year: "2017",
            // title: <Fragment>We are <br className="hidden-xs" /> conquering the Mediterranean. </Fragment>,
            description: "We are conquering the Mediterranean. Italy is no longer just about the sea and the sun. Now tourists can also admire our sweets in several stores!",
        },
        // {
        //     img: aboutMin7,
        //     yearImg: year2015,
        //     year: "2015",
        //     title: <Fragment> We are not idle <br className="hidden-xs" /> at home either</Fragment>,
        //     description: "TWe expand our home port and anchor more ships in the coves of Old Prague, in Karlova and Havelská streets.",
        // },
        {
            img: aboutMin8,
            yearImg: year2020,
            year: "2020",
            // title: <Fragment>We expand <br className="hidden-xs" /> to the Netherlands</Fragment>,
            description: "We expand to the Netherlands - Amsterdam is the next stop on our journey to conquer Europe. Our candies are making more and more people happy.",
        },
        {
            img: aboutMin9,
            yearImg: year2021,
            year: "2021",
            // title: <Fragment>We keep looking <br className="hidden-xs" /> and planning. </Fragment>,
            description: "We keep looking and planning. We're adding stores in Italy and we can't wait for every new opportunity.",
        },
        {
            img: aboutMin,
            yearImg: year2022,
            year: "2022",
            // title: <Fragment>We opened our first <br className="hidden-xs" />store in Germany in Cologne.</Fragment>,
            description:  "We opened our first store in Germany in Cologne. And in Amsterdam you can already find us at two addresses. And we are very happy about everything!",
        },
        {
            img: aboutMin7,
            yearImg: year2023,
            year: "2023",
            // title: <Fragment>The plans are  <br className="hidden-xs" /> clear, we are about to conquer Rome </Fragment>,
            description: <Fragment>The plans are clear, we are about to conquer Rome 😊 and it's definitely not our last stop...</Fragment>,
        },
    ],
    nl: [
        {
            img: aboutMin,
            yearImg: year2012,
            year: "2012",
            // title: <Fragment>Het is hier! Niets zal <br className='hidden-xs' /> ooit meer hetzelfde zijn!</Fragment>,
            description: "Het is hier! Niets zal ooit meer hetzelfde zijn! De definitieve beslissing is genomen dat Captain Candy een geweldig concept is en dat we groots gaan.",
        },
        {
            img: aboutMin2,
            yearImg: year2013,
            year: "2013",
            // title: <Fragment>We planden, <br className="hidden-xs" /> we testten,</Fragment>,
            description: "We planden, we testten, we vulden de magazijnen en de droom werd werkelijkheid. We hebben het algemene concept, de bedrijfs- en franchisestructuren opgezet en we zijn binnen.",
        },
        {
            img: aboutMin3,
            yearImg: year2014,
            year: "2014",
            // title: <Fragment>We openden de deuren van onze</Fragment>,
            description: "We openden de deuren van onze eerste winkel in Praag. We hebben heel zorgvuldig gekozen, en dankzij dat kunnen we onze klanten niet alleen premium snoepjes bieden, maar ook een perfecte ervaring elke keer dat ze ons bezoeken.",
        },
        {
            img: aboutMin4,
            yearImg: year2016,
            year: "2016",
            // title: <Fragment> Onze winkels worden steeds populairder</Fragment>,
            description: "Onze winkels worden steeds populairder bij klanten, dus we weten dat we de juiste weg hebben gekozen. We openen meer winkels in Praag en bereiden ons voor op internationale expansie.",
        },
        {
            img: aboutMin4,
            yearImg: year2017,
            year: "2017",
            // title: <Fragment>We veroveren de Middellandse Zee. </Fragment>,
            description: "We veroveren de Middellandse Zee. Italië draait niet langer alleen om de zee en de zon. Nu kunnen toeristen onze snoepjes ook in verschillende winkels bewonderen!",
        },
        {
            img: aboutMin4,
            yearImg: year2020,
            year: "2020",
            // title: <Fragment>We breiden uit <br className="hidden-xs" />  naar Nederland</Fragment>,
            description: "We breiden uit naar Nederland - Amsterdam is de volgende stop op onze reis om Europa te veroveren. Onze snoepjes maken steeds meer mensen blij.",
        },
        {
            img: aboutMin4,
            yearImg: year2021,
            year: "2021",
            // title: <Fragment>We blijven zoeken  <br className="hidden-xs" /> en plannen.</Fragment>,
            description: "We blijven zoeken en plannen. We voegen winkels toe in Italië en we kunnen niet wachten op elke nieuwe kans.",
        },
        {
            img: aboutMin4,
            yearImg: year2022,
            year: "2022",
            // title: <Fragment>We openden onze eerste winkel in Duitsland in Keulen.</Fragment>,
            description: "We openden onze eerste winkel in Duitsland in Keulen. En in Amsterdam kun je ons al op twee adressen vinden. En we zijn erg blij met alles!",
        },
        {
            img: aboutMin7,
            yearImg: year2023,
            year: "2023",
            // title: <Fragment>De plannen zijn duidelijk,</Fragment>,
            description: "De plannen zijn duidelijk, we staan op het punt Rome te veroveren 😊 en het is zeker niet onze laatste stop...",
        },
    ],
}

const routeImgData = [
    {
        step: "step1",
        imgAbout: about1,
    },
    {
        step: "step2",
        imgAbout: about2,
    },
    {
        step: "step3",
        imgAbout: about3,
    },
    {
        step: "step4",
        imgAbout: about4,
    },
    {
        step: "step5",
        imgAbout: about5,
    },
    {
        step: "step6",
        imgAbout: about6,
    },
    {
        step: "step7",
        imgAbout: about7,
    },
    {
        step: "step8",
        imgAbout: about8,
    },
    {
        step: "step9",
        imgAbout: about9,
    },
    // {
    //     step: "step10",
    //     imgAbout: about11,
    // },
    // {
    //     step: "step11",
    //     imgAbout: about9,
    // },
];

export {
    routeImgData,
    AboutData,
};